<script setup lang="ts">
defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const ratioDefault = computed(() => {
  return "400/450 @400 445/450 @480 600/600 @640 700/600 @768 768/720 @840 840/720 @900 900/720 @960 960/720 @1024 1024/720 @1100 1100/720 @1150 1150/720 @1200 1200/720 @1280 1280/720";
});
</script>

<template>
  <section v-editable="blok" class="intro">
    <BaseImage
      class="intro__image"
      v-bind="blok.image"
      :isCritical="true"
      :ratio="blok.image.aspectRatio ?? ratioDefault"
      :isOgImage="true"
    ></BaseImage>
    <div class="intro__content">
      <div class="xs:max-w-11/12 sm:max-w-3/5 mx-auto my-4">
        <div
          class="flex flex-col items-center w-full border-b-2 border-white border-solid mx-auto mb-2 xs:mb-4 max-w-3/5 xs:max-w-xs"
        >
          <Icon name="IconLogo" class="intro__logo"></Icon>
        </div>
        <div class="intro__text mb-4 px-4 xs:mb-8">
          <BaseHeadline
            class="whitespace-pre-line"
            :text="blok.headline"
            tag="h1"
            :emphasize="true"
          ></BaseHeadline>
          <p class="xs:mt-2 lg:text-xl whitespace-pre-line">
            {{ blok.description }}
          </p>
        </div>
        <BlokButton :blok="blok.link[0]"></BlokButton>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.intro {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;

  &__image {
    grid-area: 1 / 1 / 2 / 2;
    z-index: 1;

    height: 450px;

    @screen xs {
      height: 600px;
    }

    @screen md {
      height: 720px;
    }
  }

  &__content {
    display: grid;
    grid-area: 1 / 1 / 2 / 2;
    z-index: 2;
    word-break: break-word;
    hyphens: auto;
    align-items: center;
    text-align: center;
    color: white;
  }

  &__text {
    text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.8);
    text-shadow: 0px 5px 20px #000000e6;
  }

  &__logo {
    font-size: 60px;

    @screen xs {
      font-size: 100px;
    }

    @screen md {
      font-size: 140px;
    }
  }
}
</style>
